import { navigate } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { FC } from 'react';

import { GiftIcon } from '@assets/image/png';
import { ReactComponent as CartIcon } from '@assets/image/svg/cart.svg';
import Button from '@components/common/Button';
import { Layout } from '@components/layout';
import { Style } from '@features/e-cart/style';

const PaymentInfoPage: FC = () => (
  <Layout>
    <Style.Wrap style={{ padding: '0px' }}>
      <Style.Container className="bg-white left" style={{ padding: '10px 5px' }}>
        <Style.GiftHeader>
          <div className="card-icon">
            <CartIcon />
          </div>
          <FormattedMessage id="e-gift.payment-info" />
          <div className="sub-title">
            <img src={GiftIcon as string} alt="GiftIcon" />
            <FormattedMessage id="breadcrumbs.e-gift" />
          </div>
        </Style.GiftHeader>
        <div className="mt-5 mx-auto text-center" style={{ maxWidth: ' 400px' }}>
          <p className="my-3 my-md-5 text-center font-weight-800">
            <FormattedMessage id="e-gift.payment-info.enter" />
          </p>
          <div className="text-center">
            <Button
              width="250px"
              className="mb-3"
              onClick={() => {
                navigate('/e-gift/order-complete');
              }}
            >
              <FormattedMessage id="e-gift.payment-info" />
            </Button>
            <Button
              className="secondary mb-3"
              width="180px"
              onClick={() => {
                navigate('/e-gift/address-confirm');
              }}
            >
              <FormattedMessage id="back.page" />
            </Button>
          </div>
        </div>
      </Style.Container>
    </Style.Wrap>
  </Layout>
);

export default React.memo(PaymentInfoPage);
